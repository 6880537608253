<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="cookieTextTemplateSearchQuery"
			search-query-label="Cookie Text Template Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changeCookieTextTemplateSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
		>
			<template #action>
				<PrimaryActionButton
					v-if="userCanCreateUpdate"
					@click="onCreateClick()"
				>
					<v-icon left>
						mdi-plus
					</v-icon>
					Create
				</PrimaryActionButton>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Cookie Text Templates
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredCookieTextTemplates"
					sort-by="id"
					:sort-desc="true"
					@click:row="onViewClick"
				>
					<template #item.action="{ item }">
						<IconButton
							v-if="!item.isSystem && userCanCreateUpdate"
							tooltip-text="Edit Cookie Text Template"
							@click="onEditClick(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-if="item.isSystem"
							tooltip-text="View Cookie Text Template"
							@click="onViewClick(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							tooltip-text="Clone Cookie Text Template"
							@click.stop.prevent="onCloneClick(item)"
						>
							mdi-content-duplicate
						</IconButton>
						<IconButton
							v-if="userCanDelete && item.usage === 0 && !item.isSystem"
							tooltip-text="Delete Cookie Text Template"
							@click.stop.prevent="cookieTextTemplateToDelete = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="cookieTextTemplateToDelete && userCanDelete"
			:entity-name="cookieTextTemplateToDelete.name"
			entity-type="Cookie Text Template"
			@close="cookieTextTemplateToDelete = null"
			@delete="deleteCookieTextTemplate"
		/>
	</div>
</template>
<script>
import FilterBar from '../../../../../../../shared/components/filter-bar.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import ConfirmDeleteModal from '../../../../../../../shared/components/confirm-delete-modal.vue'
import { useLabels } from '../../../../../../../shared/state/admin-portal-navigation.js'
import { selectedAdminPortalBrandId, changeAdminPortalBrandFilter } from '../../../../../../../shared/state/brands.js'
import { MANAGE_COOKIE_TEXT_TEMPLATE } from '../../../../../router/route-names.js'
import { deleteCookieTextTemplate } from '../../../../../../../shared/utils/api/cookies/cookie-text-templates.js'
import { cookieTextTemplateSearchQuery, changeCookieTextTemplateSearchQuery } from '../../../../../../../shared/state/cookie-text-templates.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'

export default {
	components: {
		FilterBar,
		PrimaryActionButton,
		SectionCard,
		DataTable,
		IconButton,
		ConfirmDeleteModal
	},
	props: {
		cookieTextTemplates: Array,
		userCanCreateUpdate: Boolean,
		userCanDelete: Boolean
	},
	setup () {
		return {
			cookieTextTemplateSearchQuery,
			changeCookieTextTemplateSearchQuery,
			selectedAdminPortalBrandId,
			changeAdminPortalBrandFilter
		}
	},
	data () {
		return {
			cookieTextTemplateToDelete: null
		}
	},
	computed: {
		tableHeaders () {
			return [
				{ value: 'id', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '15%' },
				{ value: 'name', text: 'Cookie Text Template Name', width: '40%' },
				{ value: 'usage', text: 'Usage', width: '10%' },
				{ value: 'createdDate', text: 'Created Date', width: '10%' },
				{ value: 'action', text: 'Action', width: '15%', sortable: false }
			]
		},
		filteredCookieTextTemplates () {
			return this.cookieTextTemplates.filter(({ name, brandId }) => {
				let check = true
				if (cookieTextTemplateSearchQuery.value) check = name.toLowerCase().includes(cookieTextTemplateSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				return check
			}).map(textTemplate => ({
				...textTemplate
			}))
		}
	},
	created () {
		this.$emit('load-cookie-text-templates')
	},
	methods: {
		onCreateClick () {
			this.$router.push({
				name: MANAGE_COOKIE_TEXT_TEMPLATE
			})
		},
		onEditClick (item) {
			this.$router.push({
				name: MANAGE_COOKIE_TEXT_TEMPLATE,
				params: {
					cookieTextTemplateToEdit: item
				}
			})
		},
		onViewClick (item) {
			this.$router.push({
				name: MANAGE_COOKIE_TEXT_TEMPLATE,
				params: {
					cookieTextTemplateToEdit: item,
					readOnly: true
				}
			})
		},
		onCloneClick (item) {
			const cookieTextTemplateToEdit = JSON.parse(JSON.stringify(item))
			cookieTextTemplateToEdit.name = cookieTextTemplateToEdit.name + ' (Cloned)'
			this.$router.push({
				name: MANAGE_COOKIE_TEXT_TEMPLATE,
				params: {
					cookieTextTemplateToEdit,
					cloneMode: true
				}
			})
		},
		async deleteCookieTextTemplate () {
			await deleteCookieTextTemplate(this.cookieTextTemplateToDelete.id)
			showSnackbar('You have removed this cookie text template')
			this.cookieTextTemplateToDelete = null
			this.$emit('load-cookie-text-templates')
		}
	}
}
</script>
