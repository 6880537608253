<template>
	<CookieTemplateLayout
		:header-title="'Cookie Text Templates' | useLabels('Cookie Text Templates')"
		:header-caption="'Add, edit and manage cookie text templates' | useLabels('Add, edit and manage cookie text templates')"
	>
		<template #header-after>
			<Tabs
				:tab.sync="tab"
				:tabs="tabs"
			/>
		</template>
		<template #content>
			<TabItems
				:tab="tab"
				:tabs="tabs"
			>
				<template #0>
					<CookieTextTemplatesTab
						:cookie-text-templates="cookieTextTemplates"
						:user-can-create-update="userCanCreateUpdate"
						:user-can-delete="userCanDelete"
						@load-cookie-text-templates="getCookieTextTemplates"
					/>
				</template>
				<template #1>
					<CookieTextTemplateTranslationsTab
						:cookie-text-templates="cookieTextTemplates"
						:previously-selected-language="previouslySelectedLanguage"
						:user-can-create-update="userCanCreateUpdate"
						:user-can-delete="userCanDelete"
						@load-cookie-text-templates="getCookieTextTemplates"
					/>
				</template>
			</TabItems>
		</template>
	</CookieTemplateLayout>
</template>
<script>
import { mapGetters } from 'vuex'
import CookieTemplateLayout from '../cookie-template-layout.vue'
import Tabs from '../../../../../../../shared/components/tabs.vue'
import TabItems from '../../../../../../../shared/components/tab-items.vue'
import CookieTextTemplatesTab from './cookie-text-templates-tab.vue'
import CookieTextTemplateTranslationsTab from './cookie-text-template-translations-tab.vue'
import { getCookieTextTemplates } from '../../../../../../../shared/utils/api/cookies/cookie-text-templates.js'
import { CAN_CREATE_UPDATE_COOKIE_TEXT_TEMPLATES, CAN_DELETE_COOKIE_TEXT_TEMPLATES } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
export default {
	components: {
		CookieTemplateLayout,
		Tabs,
		TabItems,
		CookieTextTemplatesTab,
		CookieTextTemplateTranslationsTab
	},
	props: {
		accessedVia: String,
		previouslySelectedLanguage: Number
	},
	data () {
		const ACCESSED_VIA_COOKIE_TEXT_TEMPLATE_TRANSLATIONS = 'cookie-text-template-translations'
		return {
			ACCESSED_VIA_COOKIE_TEXT_TEMPLATE_TRANSLATIONS,
			tabs: [{ title: 'Cookie Text Templates', slot: '0' }, { title: 'Cookie Text Template Translations', slot: '1' }],
			tab: '0',
			cookieTextTemplates: []
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		userCanCreateUpdate () {
			return this.productAreaPermission(CAN_CREATE_UPDATE_COOKIE_TEXT_TEMPLATES)
		},
		userCanDelete () {
			return this.productAreaPermission(CAN_DELETE_COOKIE_TEXT_TEMPLATES)
		}
	},
	async created () {
		this.getCookieTextTemplates()
		if (this.accessedVia === this.ACCESSED_VIA_COOKIE_TEXT_TEMPLATE_TRANSLATIONS) {
			this.tab = '1'
		} else {
			this.tab = '0'
		}
	},
	methods: {
		async getCookieTextTemplates () {
			const { cookieTextTemplates } = await getCookieTextTemplates()
			this.cookieTextTemplates = cookieTextTemplates
		}
	}
}
</script>
