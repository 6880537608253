<template>
	<div class="cassie-vertical-md mt-1">
		<FilterBar
			:search-query.sync="cookieTextTemplateSearchQuery"
			search-query-label="Cookie Text Template Name"
			:selected-brand-id.sync="selectedAdminPortalBrandId"
			@persistSearchQuery="changeCookieTextTemplateSearchQuery"
			@persistSelectedBrandId="changeAdminPortalBrandFilter"
			@clearFilters="clearFilters"
		>
			<template #after-filters>
				<Dropdown
					v-model="cookieTextTemplateTranslationStatus"
					label="Translation Status"
					:items="translationStatusItems"
					custom-sort
					@input="changeCookieTextTemplateTranslationStatus($event)"
				/>
			</template>
		</FilterBar>
		<SectionCard>
			<template #title>
				Cookie Text Template Translations
			</template>
			<template #title-action>
				<Dropdown
					:value="selectedLanguageIdOrDefault"
					label="Language"
					:items="assignedLanguagesItems"
					@input="selectedLanguageId = $event"
				/>
			</template>
			<template #body>
				<DataTable
					:headers="tableHeaders"
					:items="filteredTextTemplateTranslations"
					sort-by="id"
					:sort-desc="true"
					@click:row="onManageTranslationClick($event)"
				>
					<template #item.textTemplateTranslated="{ item }">
						{{ getTranslationStatus(item) }}
					</template>
					<!-- add permissions -->
					<template #item.action="{ item }">
						<IconButton
							v-if="userCanCreateUpdate"
							tooltip-text="Edit Cookie Text Template Translation"
							@click="onManageTranslationClick(item)"
						>
							mdi-pencil
						</IconButton>
						<IconButton
							v-else
							tooltip-text="View Cookie Text Template Translation"
							@click="onEditClick(item)"
						>
							mdi-eye
						</IconButton>
						<IconButton
							v-if="userCanDelete && item.cookieTextTemplateTranslationID"
							tooltip-text="Delete Cookie Text Template Translation"
							@click.stop.prevent="textTemplateTranslationToDelete = item"
						>
							mdi-trash-can
						</IconButton>
					</template>
				</DataTable>
			</template>
		</SectionCard>
		<ConfirmDeleteModal
			v-if="textTemplateTranslationToDelete"
			:entity-name="textTemplateTranslationToDelete.name"
			entity-type="Cookie Collection Template Translation"
			@close="textTemplateTranslationToDelete = null"
			@delete="deleteTextTemplateTranslation"
		/>
	</div>
</template>
<script>
import FilterBar from '../../../../../../../shared/components/filter-bar.vue'
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../../shared/components/data-table.vue'
import IconButton from '../../../../../../../shared/components/icon-button.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import ConfirmDeleteModal from '../../../../../../../shared/components/confirm-delete-modal.vue'
import { cookieTextTemplateSearchQuery, changeCookieTextTemplateSearchQuery, changeCookieTextTemplateTranslationStatus, cookieTextTemplateTranslationStatus } from '../../../../../../../shared/state/cookie-text-templates.js'
import { selectedAdminPortalBrandId, changeAdminPortalBrandFilter } from '../../../../../../../shared/state/brands.js'
import { getAssignedLanguages } from '../../../../../../../shared/utils/api/languages.js'
import { useLabels } from '../../../../../../../shared/state/admin-portal-navigation.js'
import { MANAGE_COOKIE_TEXT_TEMPLATE_TRANSLATION } from '../../../../../router/route-names.js'
import { deleteCookieTextTemplateTranslation } from '../../../../../../../shared/utils/api/cookies/cookie-text-templates.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'

export default {
	components: {
		FilterBar,
		SectionCard,
		DataTable,
		IconButton,
		Dropdown,
		ConfirmDeleteModal
	},
	props: {
		cookieTextTemplates: Array,
		previouslySelectedLanguage: Number,
		userCanCreateUpdate: Boolean,
		userCanDelete: Boolean
	},
	setup () {
		return {
			useLabels,
			cookieTextTemplateSearchQuery,
			changeCookieTextTemplateSearchQuery,
			selectedAdminPortalBrandId,
			changeAdminPortalBrandFilter,
			cookieTextTemplateTranslationStatus,
			changeCookieTextTemplateTranslationStatus
		}
	},
	data () {
		return {
			textTemplateTranslationToEdit: null,
			textTemplateTranslationToDelete: null,
			assignedLanguages: [],
			selectedTranslationStatus: null,
			selectedLanguageId: null
		}
	},
	computed: {
		translationStatusItems () {
			return [
				{ value: null, text: 'View All' },
				{ value: true, text: 'Translated Only' },
				{ value: false, text: 'Untranslated Only' }
			]
		},
		assignedLanguagesItems () {
			const DEFAULT_LANGUAGE = 0
			return this.assignedLanguages.map(({ languageID, languageName }) => ({
				value: languageID,
				text: languageName
			})).filter(({ value }) => value !== DEFAULT_LANGUAGE)
		},
		tableHeaders () {
			return [
				{ value: 'id', text: 'ID', width: '5%' },
				{ value: 'brandName', text: useLabels('Brand'), width: '15%' },
				{ value: 'name', text: 'Cookie Text Template Name', width: '20%' },
				{ value: 'textTemplateTranslated', text: 'Translation Status', width: '20%' },
				{ value: 'createdDate', text: 'Created Date', width: '10%' },
				{ value: 'action', text: 'Action', width: '15%', sortable: false }
			]
		},
		selectedLanguageIdOrDefault () {
			if (this.selectedLanguageId !== null) return this.selectedLanguageId
			if (this.previouslySelectedLanguage != null) return this.previouslySelectedLanguage
			return this.assignedLanguagesItems[0]?.value
		},
		cookieTextTemplateTranslations () {
			return this.cookieTextTemplates.map(textTemplate => {
				const cookieTextTemplateTranslation = textTemplate.translations?.find(({ languageId }) => languageId === this.selectedLanguageIdOrDefault)
				const language = this.assignedLanguagesItems.find(({ value }) => value === this.selectedLanguageIdOrDefault)
				return {
					...textTemplate,
					textTemplateId: textTemplate?.id,
					cookieTextTemplateTranslationID: cookieTextTemplateTranslation?.textTemplateTranslationID,
					languageName: language?.text,
					languageId: language?.value,
					cookieTextTemplateTranslation: {
						preBanner: cookieTextTemplateTranslation?.preBanner,
						gpcBanner: cookieTextTemplateTranslation?.gpcBanner,
						banner: cookieTextTemplateTranslation?.banner
					}
				}
			})
		},
		filteredTextTemplateTranslations () {
			return this.cookieTextTemplateTranslations.filter(({ name, brandId, cookieTextTemplateTranslationID }) => {
				let check = true
				if (cookieTextTemplateSearchQuery.value) check = name.toLowerCase().includes(cookieTextTemplateSearchQuery.value.toLowerCase())
				if (selectedAdminPortalBrandId.value !== null) check = check && brandId === selectedAdminPortalBrandId.value
				if (cookieTextTemplateTranslationStatus.value !== null) {
					check = check && (cookieTextTemplateTranslationStatus.value ? Boolean(cookieTextTemplateTranslationID) : !cookieTextTemplateTranslationID)
				}
				return check
			})
		}
	},
	created () {
		this.loadLanguages()
	},
	methods: {
		async loadLanguages () {
			const { data: assignedLanguages } = await getAssignedLanguages()
			this.assignedLanguages = assignedLanguages
		},
		async deleteTextTemplateTranslation () {
			await deleteCookieTextTemplateTranslation(this.textTemplateTranslationToDelete.cookieTextTemplateTranslationID)
			showSnackbar(useLabels('This cookie text template translation has been removed'))
			this.textTemplateTranslationToDelete = null
			this.$emit('load-cookie-text-templates')
		},
		clearFilters () {
			changeCookieTextTemplateTranslationStatus(null)
		},
		getTranslationStatus (textTemplate) {
			if (textTemplate.cookieTextTemplateTranslationID) return 'Translated'
			return 'Untranslated'
		},
		onManageTranslationClick (item) {
			this.$router.push({
				name: MANAGE_COOKIE_TEXT_TEMPLATE_TRANSLATION,
				params: {
					cookieTextTemplate: item
				}
			})
		}
	}
}
</script>
